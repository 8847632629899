<template>
  <vs-popup
    class="blockPhraseEditPopup"
    :title="$t('search.blockedPhrase.editPopupHeader')"
    :active.sync="active"
  >
    <div ref="popup">
      <vs-row>
        <vs-col class="mb-5 w-full">
          <label>{{ $t('search.blockedPhrase.editPopupBlockedPhraseLabel') }}</label>
          <vs-input
            v-model="blockedPhrase" class="w-100"
            :danger="blockedPhrase.length < 1"
            :danger-text="$t('search.blockedPhrase.popupInvalid')"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="2" vs-offset="8">
          <b-button variant="primary" @click="update">
            {{ $t('search.blockedPhrase.editPopupSave') }}
          </b-button>
        </vs-col>
        <vs-col vs-w="2">
          <b-button variant="outline-primary" @click="hideEditPopup">
            {{ $t('search.blockedPhrase.editPopupCancel') }}
          </b-button>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>
<script>
import store from '@/store'

export default {
  name: 'BlockedPhrasesEditPopup',
  computed: {
    active: {
      get() {
        return store.state.blockedPhraseEditPopup.active
      },
      set(active) {
        store.commit('blockedPhraseEditPopup/setActive', active)
      },
    },
    blockedPhrase: {
      get() {
        return store.state.blockedPhraseEditPopup.blockedPhrase
      },
      set(blockedPhrase) {
        return store.commit('blockedPhraseEditPopup/setBlockedPhrase', blockedPhrase)
      },
    },
    rowIndex() {
      return store.state.blockedPhraseEditPopup.rowIndex
    },

  },
  methods: {
    hidePopup() {
      store.commit('blockedPhraseEditPopup/hideEditPopup')
    },
    update() {
      if (this.blockedPhrase.length < 1) {
        return
      }
      store.dispatch('blockedPhrase/updatePhrase', {
        phrase: this.blockedPhrase,
        index: this.rowIndex,
      })
    },
  },
}
</script>

<template>
  <div>
    <vs-button class="mb-2" @click="showPopup()">
      {{ $t('search.blockedPhrase.addNew') }}
    </vs-button>
    <vs-popup
      class="blockedPhrasesCreatePopup"
      :title="$t('search.blockedPhrase.createPopupHeader')"
      :button-close-hidden="true"
      :active.sync="active"
    >
      <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
        <label>{{ $t('search.blockedPhrase.createPopupPhraseLabel') }}</label>
        <vs-row>
          <vs-col class="mb-4 w-full">
            <div v-for="(blockedPhrases, index) in blockedPhrases" :key="index" ref="blockedPhrasesInputContainer">
              <vs-row class="mb-1">
                <vs-col vs-w="11">
                  <vs-input
                    :key="index"
                    v-model="blockedPhrases.phrase"
                    class="w-100"
                    size="sm"
                    :danger="!blockedPhrases.isValid"
                    :danger-text="$t('search.blockedPhrase.popupInvalid')"
                  />
                </vs-col>
                <vs-col
                  v-if="isNotFirstBlockedPhrase(index)" vs-w="1" vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="center"
                >
                  <b-button
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click.prevent="removeBlockedPhrasesInput(index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </vs-col>
              </vs-row>
              <div v-if="blockedPhrases.length - 1 > index" class="text-center">{{
                $t('search.blockedPhrase.or')
              }}
              </div>
            </div>
            <div class="text-left">
              <b-button variant="primary" size="sm" @click.prevent="addBlockedPhrasesInput()">
                <feather-icon icon="PlusCircleIcon" />
                {{ $t('search.blockedPhrase.addInputTooltip') }}
              </b-button>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="2" vs-offset="8">
            <b-button variant="primary" @click="save()">
              {{ $t('search.blockedPhrase.editPopupSave') }}
            </b-button>
          </vs-col>
          <vs-col vs-w="2">
            <b-button variant="outline-primary" @click="hidePopup()">
              {{ $t('search.blockedPhrase.editPopupCancel') }}
            </b-button>
          </vs-col>
        </vs-row>
      </b-overlay>
    </vs-popup>
  </div>
</template>

<script>
import store from '@/store'

export default {
  data() {
    return {
      active: false,
      blockedPhrases: [{ phrase: '', isValid: true }],
      dataLoading: false,
    }
  },
  methods: {
    filterNonEmptyBlockedPhrases() {
      this.blockedPhrases = this.blockedPhrases.filter((blocked, index) => index === 0 || blocked.phrase !== '')
    },
    hidePopup() {
      this.active = false
      this.filterNonEmptyBlockedPhrases()
    },
    showPopup() {
      this.active = true
    },
    isNotFirstBlockedPhrase(index) {
      return index !== 0
    },
    addBlockedPhrasesInput() {
      this.blockedPhrases.push({ phrase: '', isValid: true })
    },
    removeBlockedPhrasesInput(index) {
      this.blockedPhrases.splice(index, 1)
    },
    save() {
      this.dataLoading = true

      this.filterNonEmptyBlockedPhrases()

      if (!this.validateBlockedPhrases()) {
        this.dataLoading = false
        return
      }

      const requestData = {
        blocked: this.blockedPhrases.map(blocked => {
          console.dir(blocked)
          return blocked.phrase
        }),
      }

      store.dispatch('blockedPhrase/storePhrases', requestData)
        .finally(() => {
          this.blockedPhrases = [{ phrase: '', isValid: true }]
          this.dataLoading = false

          this.hidePopup()
          this.$root.$emit('blockedPhraseCreated')
        })
    },
    validateBlockedPhrases() {
      let allValid = true
      this.blockedPhrases.forEach((blockedPhrase, index) => {
        allValid = allValid && blockedPhrase.phrase.length > 0
        this.blockedPhrases[index].isValid = blockedPhrase.phrase.length > 0
      })
      return allValid
    },
  },
}
</script>

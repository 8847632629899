<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <vs-table class="mb-base mb-2" stripe :data="results">
        <template v-if="title" slot="header">
          <h3>{{ title }}</h3>
        </template>
        <template slot="thead">
          <vs-th v-for="(column, columnIndex) in columns" :key="columnIndex">
            <span :class="{ 'cursor-default' : column.disableOrderBy}" @click="manageOrder(column)">
              {{ column.displayName }}
              <i
                v-if="isOrderByThisColumnActive(column.name)"
                class="vs-icon notranslate icon-scale th-sort-icon material-icons null"
              >
                {{ getArrowUpOrDown() }}
              </i>
            </span>
          </vs-th>
          <vs-th>{{ $t('search.blockedPhrase.optionsHeader') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, rowIndex) in data" :id="`row-${data[rowIndex].id}`" :key="rowIndex">
            <vs-td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :data="data[rowIndex][column.name]"
              :class="column.class"
            >
              {{ data[rowIndex][column.name] }}
            </vs-td>
            <vs-td>
              <a href="#" class="mr-1" @click.prevent="edit(rowIndex)">
                <vs-tooltip :text="$t('search.blockedPhrase.editTooltip')">
                  <edit-icon class="text-primary" />
                </vs-tooltip>
              </a>
              <a href="#" @click.prevent="confirmDelete(rowIndex)">
                <vs-tooltip :text="$t('search.blockedPhrase.deleteTooltip')">
                  <trash-2-icon class="text-danger" />
                </vs-tooltip>
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </b-overlay>
    <vs-pagination v-model="page" :total="totalPages" :max="7" />
  </div>
</template>

<script>
import store from '@/store'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'

export default {
  components: {
    EditIcon,
    Trash2Icon,
  },
  props: [
    'columns', 'title',
  ],
  computed: {
    dataLoading() {
      return store.state.blockedPhrase.dataLoading
    },
    results() {
      return store.state.blockedPhrase.results
    },
    totalPages() {
      return store.state.blockedPhrase.totalPages
    },
    page: {
      get() {
        return store.state.blockedPhrase.page
      },
      set(page) {
        store.commit('blockedPhrase/setPage', page)
      },
    },
    sort() {
      return store.state.blockedPhrase.sort
    },
    orderBy() {
      return store.state.blockedPhrase.orderBy
    },
  },
  watch: {
    page() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.$root.$on('blockedPhraseCreated', () => {
      this.getData()
    })
  },
  methods: {
    isOrderByThisColumnActive(columnName) {
      return this.orderBy === columnName
    },
    getArrowUpOrDown() {
      return this.sort === 'asc' ? 'expand_less' : 'expand_more'
    },
    manageOrder(column) {
      if (typeof column.disableOrderBy !== 'undefined' && column.disableOrderBy) {
        return
      }
      if (this.orderBy === column.name) {
        if (this.sort === 'asc') {
          store.commit('blockedPhrase/setSort', 'desc')
        } else {
          store.commit('blockedPhrase/setSort', 'asc')
        }
        this.getData()
        return
      }
      store.commit('blockedPhrase/setOrderBy', column.name)
      store.commit('blockedPhrase/setSort', 'desc')

      this.getData()
    },
    getData() {
      store.dispatch('blockedPhrase/getData')
    },
    edit(rowIndex) {
      store.commit(
        'blockedPhraseEditPopup/editPopup',
        { rowIndex, blockedPhrase: this.results[rowIndex].phrase },
      )
    },
    confirmDelete(rowIndex) {
      const self = this
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: self.$t('search.blockedPhrase.deleteConfirmationHeader'),
        text: self.$t('search.blockedPhrase.deleteConfirmationText'),
        acceptText: self.$t('search.blockedPhrase.deleteConfirmationAcceptText'),
        cancelText: self.$t('search.blockedPhrase.deleteConfirmationCancelText'),
        accept() {
          self.delete(rowIndex)
        },
      })
    },
    delete(rowIndex) {
      store.dispatch('blockedPhrase/deletePhrase', {
        index: rowIndex,
        phrase: this.results[rowIndex].phrase,
      })
    },
  },

}
</script>

<style>
.vs-pagination--ul {
  margin-bottom: 0;
}

.vs-pagination--li {
  width: auto;
  min-width: 35px;
}

.vs-pagination--li.is-current {
  padding-left: 3px;
  padding-right: 5px;
  min-width: 35px;
  border-radius: 35px;
}

.vs-pagination--li.is-current .effect {
  border-radius: 35px;
}

.con-vs-tooltip {
  display: inline-block
}
</style>

<template lang="html">
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <b-card :title="$t('search.blockedPhrase.header')">

          <blocked-phrases-create-popup />

          <blocked-phrases-table
            api-endpoint="/search/blockedPhrase"
            :columns="blockedPhrases.columns"
          />
          <BlockedPhrasesEditPopup />

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import BlockedPhrasesTable from '@/views/components/search/BlockedPhrases/BlockedPhrasesTable.vue'
import BlockedPhrasesCreatePopup from '@/views/components/search/BlockedPhrases/BlockedPhrasesCreatePopup.vue'
import BlockedPhrasesEditPopup from '@/views/components/search/BlockedPhrases/BlockedPhrasesEditPopup.vue'

export default {
  components: {
    BlockedPhrasesCreatePopup,
    BlockedPhrasesEditPopup,
    BlockedPhrasesTable,
  },
  data() {
    return {
      blockedPhrases: {
        columns: [
          {
            displayName: this.$t('search.blockedPhrase.phraseColumnHeader'),
            name: 'phrase',
          },
        ],
      },
    }
  },
}
</script>
